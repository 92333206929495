import React, { Suspense, useLayoutEffect } from "react";
import { BrowserRouter as Router, Route, withRouter } from "react-router-dom";

import Preloader from "./components/layouts/Preloader";

// Pages
const ComingSoon = React.lazy(() => import("./components/pages/ComingSoon"));
const Home = React.lazy(() => import("./components/pages/Home"));
const Hometwo = React.lazy(() => import("./components/pages/Hometwo"));
const Homethree = React.lazy(() => import("./components/pages/Homethree"));
const Roomgrid = React.lazy(() => import("./components/pages/Roomgrid"));
// Marko
const SpecialOffers = React.lazy(() => import("./components/pages/SpecialOffers"));
const SpecialOffer = React.lazy(() => import("./components/pages/SpecialOffer"));

const Roomlist = React.lazy(() => import("./components/pages/Roomlist"));
const Roomdetails = React.lazy(() => import("./components/pages/Roomdetails"));
const Blog = React.lazy(() => import("./components/pages/Blog"));
const Blogstandard = React.lazy(() =>
  import("./components/pages/Blogstandard")
);
const Blogdetails = React.lazy(() => import("./components/pages/Blogdetails"));
const Contact = React.lazy(() => import("./components/pages/Contact"));
const About = React.lazy(() => import("./components/pages/About"));
const Gallery = React.lazy(() => import("./components/pages/Gallery"));
const Restaurant = React.lazy(() => import("./components/pages/Restaurant"));
const Places = React.lazy(() => import("./components/pages/Places"));
const Placesdetails = React.lazy(() =>
  import("./components/pages/Placesdetails")
);
const Offers = React.lazy(() => import("./components/pages/Offers"));
const Menu = React.lazy(() => import("./components/pages/Menu"));
const TOS = React.lazy(() => import("./components/pages/TOS"));
const HouseRules = React.lazy(() => import("./components/pages/HouseRules"));
const PaymentResult = React.lazy(() =>
  import("./components/pages/PaymentResult")
);

// Scroll to Top
const ScrollToTop = withRouter(({ children, location: { pathname } }) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children || null;
});

function App() {
  return (
    <Router>
      <Suspense fallback={<div></div>}>
        <ScrollToTop>
          {/* <Preloader /> */}
          <Route path="/" exact component={Hometwo /* ComingSoon */} />
          {/* <Route path="/home-v1" exact component={Home} />
          <Route path="/home-v2" exact component={Hometwo} />
          <Route path="/home-v3" exact component={Homethree} /> */}
          <Route path="/room-grid" exact component={Roomgrid} />
          <Route path="/special-offers" exact component={SpecialOffers} />

          <Route path="/special-offers/:id" exact component={SpecialOffer} />
          {/* <Route path="/room-list" exact component={Roomlist} /> */}
          {/*<Route path="/room-details" exact component={Roomdetails} />
          <Route path="/blog" exact component={Blog} />
          <Route
            path="/blog/cat/:catId"
            exact
            component={props => (
              <Blog {...props} key={window.location.pathname} />
            )}
          />
          <Route
            path="/blog/tag/:tagId"
            exact
            component={props => (
              <Blog {...props} key={window.location.pathname} />
            )}
          />
          <Route
            path="/blog/user/:userId"
            exact
            component={props => (
              <Blog {...props} key={window.location.pathname} />
            )}
          />
          <Route path="/blog-standard" exact component={Blogstandard} />
          <Route path="/blog-details/:id" exact component={Blogdetails} /> 
          <Route path="/contact" exact component={Contact} />*/}
          <Route path="/about" exact component={About} />
          <Route path="/gallery" exact component={Gallery} />
          {/*<Route path="/restaurant" exact component={Restaurant} />
          <Route path="/places" exact component={Places} />
          <Route path="/places-details/:id" exact component={Placesdetails} /> 
          <Route path="/offers" exact component={Offers} />
          <Route path="/menu" exact component={Menu} />*/}
          <Route path="/tos" exact component={TOS} />
          <Route path="/rules" exact component={HouseRules} />
          <Route path="/paymentResult" exact component={PaymentResult} />
        </ScrollToTop>
      </Suspense>
    </Router>
  );
}

export default App;
