import React, { Component } from "react";

class Preloader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchSuccess: false
    };
  }

  componentDidMount() {
    window.onload = () => {
      this.setState({
        fetchSuccess: true
      });
    };
  }

  render() {
    return (
      <div
        className={`preloader ${
          this.state.fetchSuccess ? "preloader-none" : ""
        }`}
      ></div>
    );
  }
}

export default Preloader;
